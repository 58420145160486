import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "p-grid" }
const _hoisted_6 = { class: "p-field p-col-12 p-fluid note-info" }
const _hoisted_7 = {
  key: 0,
  class: "p-field p-col-12 p-fluid"
}
const _hoisted_8 = { for: "title" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_11 = ["for"]
const _hoisted_12 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_NoteFilesCard = _resolveComponent("NoteFilesCard")!
  const _component_NoteDeleteModal = _resolveComponent("NoteDeleteModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingComponent, {
        title: _ctx.editMode ? _ctx.$t('notes.edit-note') : _ctx.noteFormDto.title,
        divider: false,
        "sub-heading": true
      }, {
        right: _withCtx(() => [
          _renderSlot(_ctx.$slots, "headingActions", {}, () => [
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              disabled: !_ctx.userCanEditMedicalTest,
              class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-edit",
                  "aria-label": _ctx.$t('common.edit'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["disabled", "class"])), [
              [
                _directive_tooltip,
                _ctx.$t('common.edit'),
                void 0,
                { bottom: true }
              ]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
              disabled: !_ctx.userCanEditMedicalTest,
              onClick: _ctx.onDeleteNote
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-trash_full",
                  "aria-label": _ctx.$t('common.delete'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["class", "disabled", "onClick"])), [
              [
                _directive_tooltip,
                _ctx.$t('common.delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ], true)
        ]),
        _: 3
      }, 8, ["title"]),
      (_ctx.noteFormDto)
        ? (_openBlock(), _createElementBlock("form", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "icon-calendar" }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.dateFormat.formatDateAndHour(_ctx.dateFormat.formatLocalTimezone(_ctx.note.creationDate))), 1)
                ]),
                _createElementVNode("span", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "icon-user" }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.note.professional.name + ' ' + _ctx.note.professional.surname), 1)
                ])
              ]),
              (_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('notes.title')), 1),
                    _createVNode(_component_HealzInput, {
                      id: "title",
                      modelValue: _ctx.noteFormDto.title,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.noteFormDto.title) = $event)),
                      editable: _ctx.editMode,
                      class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateNoteForm.title.valid }),
                      type: "text"
                    }, null, 8, ["modelValue", "editable", "class"]),
                    (_ctx.showValidation && !_ctx.validateNoteForm.title.valid)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${_ctx.validateNoteForm.title.message}`)), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("label", {
                      key: 0,
                      for: 'message-' + _ctx.note.id
                    }, _toDisplayString(_ctx.$t('notes.message')), 9, _hoisted_11))
                  : _createCommentVNode("", true),
                (_ctx.editMode)
                  ? (_openBlock(), _createBlock(_component_Textarea, {
                      key: 1,
                      id: 'message-' + _ctx.note.id,
                      modelValue: _ctx.noteFormDto.message,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.noteFormDto.message) = $event)),
                      editable: _ctx.editMode,
                      rows: "5",
                      class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateNoteForm.message.valid }),
                      "auto-resize": true,
                      type: "text"
                    }, null, 8, ["id", "modelValue", "editable", "class"]))
                  : _createCommentVNode("", true),
                (_ctx.showValidation && !_ctx.validateNoteForm.message.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.$t(`${_ctx.validateNoteForm.message.message}`)), 1))
                  : _createCommentVNode("", true),
                (!_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: "c-message__text",
                      innerHTML: _ctx.formatedMessage
                    }, null, 8, _hoisted_13))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_NoteFilesCard, {
        ref: "noteFilesCardRef",
        "edit-mode": _ctx.editMode,
        patientId: _ctx.patientId,
        documents: _ctx.note.documents
      }, null, 8, ["edit-mode", "patientId", "documents"]),
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_Button, {
              class: "p-button-secondary p-button-medium",
              label: _ctx.$t('common.cancel'),
              onClick: _ctx.onCancelEdition
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              class: "p-button p-button-info p-button-medium",
              loading: _ctx.waitSubmit,
              label: _ctx.$t('common.save'),
              onClick: _ctx.onSubmit
            }, null, 8, ["loading", "label", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NoteDeleteModal, {
      ref: "noteDeleteModalRef",
      noteId: _ctx.note.id,
      patientId: _ctx.note.patientId,
      onNoteDeleted: _ctx.onNoteDeleted
    }, null, 8, ["noteId", "patientId", "onNoteDeleted"])
  ], 64))
}