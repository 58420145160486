
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import NoteCard from '@/components/notes/NoteCard.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { Note } from '@/models/Note';
import { Patient } from '@/models/Patient';
import { noteService } from '@/services/NoteService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import CreateNoteCard from './CreateNoteCard.vue';
import EmptyState from '@/components/EmptyState.vue';

export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    NoteCard,
    CreateNoteCard,
    EmptyState,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const notes: Ref<Note[] | undefined> = ref();
    const loadingNotes = ref(false);

    const patientId = computed(() => props.patient.id);
    const creatingNote = ref(false);

    const fetchNotes = async () => {
      loadingNotes.value = true;
      const result = await noteService.findAll(patientId.value);
      if (!(result instanceof ServiceError)) {
        notes.value = [...result].sort(
          (a, b) => dateFormat.getTimeFromDateString(b.creationDate) - dateFormat.getTimeFromDateString(a.creationDate),
        );
      }
      loadingNotes.value = false;
    };

    onMounted(async () => {
      await fetchNotes();
    });

    const onNotesUpdated = async () => {
      await fetchNotes();
    };

    const onNewNote = async () => {
      creatingNote.value = true;
    };

    const onCreatedNote = async () => {
      creatingNote.value = false;
      await fetchNotes();
    };

    const onCancelCreateNote = async () => {
      creatingNote.value = false;
    };

    const onNoteDeleted = async () => {
      await fetchNotes();
    };

    return {
      notes,
      loadingNotes,
      route,
      creatingNote,

      fetchNotes,
      onNotesUpdated,
      onNewNote,
      onCreatedNote,
      onCancelCreateNote,
      onNoteDeleted,
    };
  },
});
