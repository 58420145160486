import { Document, EntityType } from '@/models/Document';
import axios, { AxiosRequestConfig } from 'axios';
import { useProfileStore } from '@/store/profile.module';
import { ServiceError } from './util/ServiceError';
import handleError from './util/ErrorHandler';

let axiosInstance: any;
let axiosInstanceToUploadS3: any;
let storeProfile: any;

class DocumentService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
    axiosInstanceToUploadS3 = axios.create({});
  }

  async getContentURLFromS3(
    patientId: string,
    entityType: EntityType,
    entityId: string,
    documentId: string,
  ): Promise<any | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    const options: AxiosRequestConfig = {
      params: {
        patientId: patientId,
        entityType,
        entityId,
      },
    };

    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_DOCUMENT_SERVICE}/document/${documentId}/contentUrlFromS3?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async createDocument(
    patientId: string,
    entityType: EntityType,
    entityId: string,
    document: Document,
  ): Promise<Document | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    const options: AxiosRequestConfig = {
      params: {
        entityType,
        entityId,
        patientId,
      },
    };

    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_DOCUMENT_SERVICE}/document/?accountId=${storeProfile.accountId}`,
        document,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async deleteDocument(
    patientId: string,
    entityType: EntityType,
    entityId: string,
    documentId: string,
  ): Promise<Document | ServiceError> {
    storeProfile = useProfileStore();
    axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    const options: AxiosRequestConfig = {
      params: {
        entityType,
        entityId,
        patientId,
      },
    };

    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_DOCUMENT_SERVICE}/document/${documentId}/?accountId=${storeProfile.accountId}`,
        options,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async generateURLToUploadToS3(
    patientId: string,
    entityType: EntityType,
    entityId: string,
    documentId: string,
  ): Promise<any | ServiceError> {
    try {
      const networkResponse = await axiosInstance.get(
        `${process.env.VUE_APP_API_DOCUMENT_SERVICE}/document/${documentId}/s3Url?accountId=${storeProfile.accountId}&entityId=${entityId}&entityType=${entityType}&patientId=${patientId}`,
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
  async uploadToS3(url: string, file: File): Promise<any | ServiceError> {
    axiosInstanceToUploadS3.defaults.headers['Content-Type'] = file.type;

    try {
      const networkResponse = await axiosInstanceToUploadS3.put(url, file);
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}

export const documentService = new DocumentService();
