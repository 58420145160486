import { NoteFormDto } from '@/models/Note';
import { isObjectValid, required, ValidationResult } from './form';

export interface NewNoteFormValidity {
  title: ValidationResult;
  message: ValidationResult;
}

export function isNewNoteFormValid(form: NewNoteFormValidity) {
  return isObjectValid<NewNoteFormValidity>(form);
}

export function newNoteForm(note: NoteFormDto): NewNoteFormValidity {
  const title = required(note.title);
  const message = required(note.message);

  return {
    title,
    message,
  };
}
