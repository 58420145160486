
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { NoteFormDto } from '@/models/Note';
import { noteService } from '@/services/NoteService';
import { ServiceError } from '@/services/util/ServiceError';
import 'chartjs-adapter-moment';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { newNoteForm, isNewNoteFormValid } from '@/validation/noteForm';
import HeadingComponent from '../HeadingComponent.vue';
import NoteFilesCard from './NoteFilesCard.vue';

export default defineComponent({
  components: { HealzInput, HeadingComponent, NoteFilesCard },
  emits: ['created-note', 'cancel-create-note'],
  setup(props, { emit }) {
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();

    const waitSubmit = ref(false);
    const showValidation = ref(false);
    const noteFormDto = ref<NoteFormDto>({} as NoteFormDto);
    const validateNoteForm = computed(() => newNoteForm(noteFormDto.value));
    const valid = computed(() => {
      if (!noteFormDto.value) {
        return false;
      }

      return isNewNoteFormValid(validateNoteForm.value);
    });
    const patientId = route.params.patientId as string;
    const noteFilesCardRef = ref();

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }

      try {
        waitSubmit.value = true;
        const result = await noteService.create(patientId, noteFormDto.value);
        if (result instanceof ServiceError) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorCreateNote')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } else {
          await noteFilesCardRef.value.uploadFiles(result);
          emit('created-note');
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successCreateNote')}`, life: 3000 });
        }
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateNote')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } finally {
        waitSubmit.value = false;
      }
    };

    const onCancelEdition = async () => {
      emit('cancel-create-note');
    };

    return {
      dateFormat,
      noteFormDto,
      waitSubmit,
      patientId,
      noteFilesCardRef,
      validateNoteForm,
      showValidation,
      onSubmit,
      onCancelEdition,
    };
  },
});
