import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-12" }
const _hoisted_4 = {
  key: 1,
  class: "c-notes-container"
}
const _hoisted_5 = {
  key: 2,
  class: "c-notes-empty-state-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_CreateNoteCard = _resolveComponent("CreateNoteCard")!
  const _component_NoteCard = _resolveComponent("NoteCard")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      description: _ctx.$t('notes.information'),
      "heading-icon": "icon-note"
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onNewNote
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notes.new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "description"]),
    _createElementVNode("main", _hoisted_1, [
      _withDirectives(_createVNode(_component_LoadingComponent, { "spinner-color": "lavender-700" }, null, 512), [
        [_vShow, _ctx.loadingNotes]
      ]),
      (!_ctx.loadingNotes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.creatingNote)
                ? (_openBlock(), _createBlock(_component_CreateNoteCard, {
                    key: 0,
                    onCancelCreateNote: _ctx.onCancelCreateNote,
                    onCreatedNote: _ctx.onCreatedNote
                  }, null, 8, ["onCancelCreateNote", "onCreatedNote"]))
                : _createCommentVNode("", true),
              (_ctx.notes && _ctx.notes.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (note) => {
                      return (_openBlock(), _createBlock(_component_NoteCard, {
                        key: note.id,
                        note: note,
                        onNoteDeleted: _ctx.onNoteDeleted
                      }, null, 8, ["note", "onNoteDeleted"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_EmptyState, {
                      heading: _ctx.$t('notes.isEmtyTitle'),
                      text: _ctx.$t('notes.isEmptyMessage'),
                      secondary: false,
                      icon: "icon-note"
                    }, null, 8, ["heading", "text"])
                  ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}