import { Note, NoteFormDto } from '@/models/Note';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

let axiosInstance: AxiosInstance;
const storeProfile = useProfileStore();

class NoteService {
  constructor() {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async create(patientId: string, note: NoteFormDto): Promise<Note | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.post(
        `${process.env.VUE_APP_API_NOTE_SERVICE}/note?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        note,
      );
      return networkResponse.data as Note;
    } catch (error) {
      return handleError(error);
    }
  }

  async update(patientId: string, id: string, updatePlan: NoteFormDto): Promise<Note | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.put(
        `${process.env.VUE_APP_API_NOTE_SERVICE}/note/${id}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
        updatePlan,
      );
      return networkResponse.data as Note;
    } catch (error) {
      return handleError(error);
    }
  }

  async findAll(patientId: string): Promise<Note[] | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;

    const options: AxiosRequestConfig = {
      params: {
        patientId,
      },
    };

    try {
      const response = await axiosInstance.get<Note[]>(
        `${process.env.VUE_APP_API_NOTE_SERVICE}/note?accountId=${storeProfile.accountId}`,
        options,
      );

      console.debug(`>[NoteService] Succeed : request findAll. Response data: `, response.data);
      return response.data;
    } catch (error) {
      console.debug(`>[NoteService] Error: request findAll`);
      return handleError(error);
    }
  }

  async delete(patientId: string, id: string): Promise<void | ServiceError> {
    axiosInstance.defaults.headers.authorization = 'Bearer ' + storeProfile.authToken;
    try {
      const networkResponse = await axiosInstance.delete(
        `${process.env.VUE_APP_API_NOTE_SERVICE}/note/${id}?accountId=${storeProfile.accountId}&patientId=${patientId}`,
      );
      return networkResponse.data;
    } catch (error) {
      throw handleError(error);
    }
  }
}

export const noteService = new NoteService();
