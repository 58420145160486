
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { Note, NoteFormDto } from '@/models/Note';
import { noteService } from '@/services/NoteService';
import { useProfileStore } from '@/store/profile.module';
import 'chartjs-adapter-moment';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import HeadingComponent from '../HeadingComponent.vue';
import NoteDeleteModal from '../notes/NoteDeleteModal.vue';
import NoteFilesCard from './NoteFilesCard.vue';
import { isNewNoteFormValid, newNoteForm } from '@/validation/noteForm';

export default defineComponent({
  components: { HealzInput, HeadingComponent, NoteDeleteModal, NoteFilesCard },
  emits: ['note-deleted'],
  props: {
    note: {
      type: Object as PropType<Note>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const toast = useToast();
    const storeProfile = useProfileStore();
    const { t } = useI18n();

    const noteDeleteModalRef = ref();
    const noteFilesCardRef = ref();

    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const editMode = ref(false);
    const waitSubmit = ref(false);
    const showValidation = ref(false);

    const noteFormDto = ref<NoteFormDto>(new NoteFormDto(props.note));
    const validateNoteForm = computed(() => newNoteForm(noteFormDto.value));
    const valid = computed(() => {
      if (!noteFormDto.value) {
        return false;
      }

      return isNewNoteFormValid(validateNoteForm.value);
    });
    const patientId = route.params.patientId as string;

    const formatedMessage = computed(() => {
      const regexUrl = /(?:https?:\/\/|www\.)[^\s]+/g;
      const urlsFound = noteFormDto.value.message.match(regexUrl) || [];
      let contentMessage = noteFormDto.value.message;

      urlsFound.forEach((url: string) => {
        contentMessage = replaceUrlWithLink(url, contentMessage);
      });

      return getHtmlContent(contentMessage);
    });

    function replaceUrlWithLink(url: string, textMessage: string) {
      return textMessage.replace(url, `<a class="link" href="${url}" target="_blank" >${url}</a>`);
    }

    function getHtmlContent(contentMessage: string) {
      return `<p class="text">${contentMessage}</p>`;
    }

    const userCanEditMedicalTest = computed(() => {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !props.note.professional.id || !isSpecialistUser.value) {
        return false;
      }

      return props.note.professional.id === storeProfile.userId;
    });

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      try {
        waitSubmit.value = true;
        const updatedNote = await noteService.update(patientId, props.note.id, noteFormDto.value);
        await noteFilesCardRef.value?.uploadFiles(updatedNote);
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditNote')}`, life: 3000 });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditNote')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } finally {
        waitSubmit.value = false;
        editMode.value = false;
      }
    };

    const onDeleteNote = async () => {
      noteDeleteModalRef.value?.openDialog();
    };

    const onNoteDeleted = async () => {
      emit('note-deleted');
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      noteFilesCardRef.value?.resetLoadedFiles();
      setInitialVaules();
    };

    const setInitialVaules = () => {
      if (noteFormDto.value) {
        noteFormDto.value = new NoteFormDto(props.note);
      }
    };

    return {
      dateFormat,
      noteFormDto,
      noteDeleteModalRef,
      noteFilesCardRef,
      editMode,
      waitSubmit,
      formatedMessage,
      userCanEditMedicalTest,
      patientId,
      validateNoteForm,
      showValidation,
      onSubmit,
      onCancelEdition,
      onDeleteNote,
      onNoteDeleted,
    };
  },
});
