import { UserTableDto } from './User';
import { Document } from './Document';
class NoteFormDto {
  title: string;
  message: string;
  professionalId: string;
  documents: Array<Document>;

  constructor(note: Note) {
    this.title = note.title;
    this.message = note.message;
    this.professionalId = note.professional.id;
    this.documents = note.documents;
  }
}

interface Note {
  id: string;
  patientId: string;
  title: string;
  message: string;
  professional: UserTableDto;
  creationDate: string;
  documents: Array<Document>;
}

export { Note, NoteFormDto };
