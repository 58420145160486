import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "note-files-container" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "upload-document-button-text" }
const _hoisted_4 = { class: "documents-list-container" }
const _hoisted_5 = { class: "document-item__text" }
const _hoisted_6 = { class: "document-item__title" }
const _hoisted_7 = { class: "document-item__actions" }
const _hoisted_8 = { class: "document-item__download" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.editMode || _ctx.newNote || (_ctx.documents && _ctx.documents.length > 0))
      ? (_openBlock(), _createBlock(_component_HeadingComponent, {
          key: 0,
          title: _ctx.$t('notes.documents'),
          divider: false,
          "sub-heading": true
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref: "fileInputRef",
      accept: ".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .txt, .zip",
      class: "upload__input",
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileSelect && _ctx.onFileSelect(...args)))
    }, null, 544),
    (_ctx.editMode || _ctx.newNote)
      ? _withDirectives((_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "upload-document-button",
          disabled: _ctx.isFileBeingCropped,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openFileInput && _ctx.openFileInput(...args)))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("i", {
            class: "icon-plus_square",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notes.addFile')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notes.fileMaxSize')), 1)
          ])
        ], 8, _hoisted_2)), [
          [
            _directive_tooltip,
            _ctx.$t('common.attachFile'),
            void 0,
            { bottom: true }
          ]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadFiles, (document, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: document.name + '-' + index,
          class: "document-item-container"
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "document-item__icon" }, [
            _createElementVNode("i", { class: "icon-file_blank_outline" })
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(document.name), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (document.name && document.url)
                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "p-button-rounded p-button-only-icon p-button-only-icon--dark",
                    onClick: ($event: any) => (_ctx.onDownloadFileContent(document.name, document.url))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: "icon-download",
                        "aria-label": _ctx.$t('common.download'),
                        "aria-hidden": "true"
                      }, null, 8, _hoisted_9)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [
                      _directive_tooltip,
                      _ctx.$t('common.download'),
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            (_ctx.editMode || _ctx.newNote)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "p-button-rounded p-button-only-icon p-button-only-icon--dark",
                  onClick: ($event: any) => (_ctx.deleteLoadedImage(index))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: "icon-trash_full",
                      "aria-label": _ctx.$t('common.delete'),
                      "aria-hidden": "true"
                    }, null, 8, _hoisted_10)
                  ]),
                  _: 2
                }, 1032, ["onClick"])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('common.delete'),
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}